import React, { FC, useContext } from "react";
import Script from "next/script";
import { AdsContext } from "../context/Ads/ads";
import { publicationTypes } from "common/context/Publication/PublicationContext";
import { PublicationContext } from "common/context/Publication";

interface Props {
  ads?: any;
  children: any;
  debug?: boolean;
  enableLazyload?: boolean;
  pub?: any;
}

export const AdsProvider: FC<Props> = ({
  // ads,
  children,
  // debug = false,
  // enableLazyload = true,
  // pub,
}) => {
  // const { config } = useContext<publicationTypes>(PublicationContext);
  // const [isLoading, setIsLoading] = useState(true);
  // const isSmallScreen = useMediaQuery({
  //   query: `(max-width: ${defaultBreakpoints.small})`,
  // });
  // const router = useRouter();
  // const [ads, setAds] = useState<any>([
  //   {
  //     slotId: `${config?.media_force}${
  //       router.asPath === "/" || router.asPath === "/?googfc"
  //         ? "/homepage"
  //         : router.query.primary_route && `/${router.query.primary_route}`
  //     }`,
  //     sizeMappings: [
  //       [1, 1],
  //       [1, 2],
  //     ],
  //     divId: "wp",
  //   },
  // ]);
  // const addSlot = (slotName: any, sizes: any, target: any) => {
  //   const newSlot = {
  //     slotId: `${config.media_force}${
  //       router.asPath === "/" || router.asPath === "/?googfc"
  //         ? "/homepage"
  //         : router.query.primary_route && `/${router.query.primary_route}`
  //     }`,
  //     sizeMappings: sizes,
  //     divId: slotName,
  //     target,
  //   };
  //   setAds((prevState: any) => [...prevState, newSlot]);
  // };

  // const isMediaScreen = useMediaQuery({
  //   query: `(max-width: ${defaultBreakpoints.large})`,
  // });

  // const renderTopBar = () => {
  //   if (isSmallScreen) {
  //     return [320, 50];
  //   }
  //   if (isMediaScreen) {
  //     return [728, 90];
  //   } else {
  //     return [970, 250];
  //   }
  // };

  // const renderSkin = () => {
  //   if (isSmallScreen) {
  //     return [
  //       [1, 1],
  //       [1, 2],
  //     ];
  //   } else {
  //     return [[1, 1]];
  //   }
  // };

  // useEffect(() => {

  //  const ads: any = [];
  //   if (document.querySelector(`#wp`)) {
  //     console.log("teads");
  //     ads.push({
  //       slotId: `${config?.media_force}${
  //         router.asPath === "/" || router.asPath === "/?googfc"
  //           ? "/homepage"
  //           : router.query.primary_route && `/${router.query.primary_route}`
  //       }`,
  //       sizeMappings: [
  //         [1, 1],
  //         [1, 2],
  //       ],
  //       divId: "wp",
  //     });
  //   }

  //   if (document.querySelector(`#leaderboard`)) {
  //     console.log("teads");
  //     ads.push({
  //       slotId: `${config?.media_force}${
  //         router.asPath === "/" || router.asPath === "/?googfc"
  //           ? "/homepage"
  //           : router.query.primary_route && `/${router.query.primary_route}`
  //       }`,
  //       sizeMappings: renderTopBar(),
  //       divId: "leaderboard",
  //       target: "billboard",
  //     });
  //   }
  //   if (document.querySelector(`#teads`)) {
  //     console.log("teads");
  //     ads.push({
  //       slotId: `${config.media_force}${
  //         router.asPath === "/" || router.asPath === "/?googfc"
  //           ? "/homepage"
  //           : router.query.primary_route && `/${router.query.primary_route}`
  //       }`,
  //       sizeMappings: [[2, 1]],
  //       divId: "teads",

  //       //tageting
  //     });
  //   }
  //   if (document.querySelector(`#mpu1`)) {
  //     console.log("mpu1");
  //     ads.push({
  //       slotId: `${config.media_force}${
  //         router.asPath === "/" || router.asPath === "/?googfc"
  //           ? "/homepage"
  //           : router.query.primary_route && `/${router.query.primary_route}`
  //       }`,
  //       sizeMappings: [[300, 250]],
  //       divId: "mpu1",
  //       target: "mpu1",
  //       //tageting
  //     });
  //   }

  //   if (document.querySelector(`#mpu2`)) {
  //     console.log("mpu2");
  //     ads.push({
  //       slotId: `${config.media_force}${
  //         router.asPath === "/" || router.asPath === "/?googfc"
  //           ? "/homepage"
  //           : router.query.primary_route && `/${router.query.primary_route}`
  //       }`,
  //       sizeMappings: [[300, 250]],
  //       divId: "mpu2",
  //       target: "mpu2",
  //       //tageting
  //     });
  //   }

  //   if (document.querySelector(`#mpu3`)) {
  //     console.log("mpu3");
  //     ads.push({
  //       slotId: `${config.media_force}${
  //         router.asPath === "/" || router.asPath === "/?googfc"
  //           ? "/homepage"
  //           : router.query.primary_route && `/${router.query.primary_route}`
  //       }`,
  //       sizeMappings: [[300, 250]],
  //       divId: "mpu3",
  //       target: "mpu3",
  //       //tageting
  //     });
  //   }
  //   if (document.querySelector(`#mpu4`)) {
  //     console.log("mpu4");
  //     ads.push({
  //       slotId: `${config.media_force}${
  //         router.asPath === "/" || router.asPath === "/?googfc"
  //           ? "/homepage"
  //           : router.query.primary_route && `/${router.query.primary_route}`
  //       }`,
  //       sizeMappings: [[300, 250]],
  //       divId: "mpu4",
  //       target: "mpu4",
  //       //tageting
  //     });
  //   }
  //   if (document.querySelector(`#mpu5`)) {
  //     console.log("mpu4");
  //     ads.push({
  //       slotId: `${config.media_force}${
  //         router.asPath === "/" || router.asPath === "/?googfc"
  //           ? "/homepage"
  //           : router.query.primary_route && `/${router.query.primary_route}`
  //       }`,
  //       sizeMappings: [[300, 250]],
  //       divId: "mpu5",
  //       target: "mpu5",
  //       //tageting
  //     });
  //   }

  //   if (document.querySelector(`#mpu6`)) {
  //     ads.push({
  //       slotId: `${config.media_force}${
  //         router.asPath === "/" || router.asPath === "/?googfc"
  //           ? "/homepage"
  //           : router.query.primary_route && `/${router.query.primary_route}`
  //       }`,
  //       sizeMappings: [[300, 250]],
  //       divId: "mpu6",
  //       target: "mpu6",
  //     });
  //   }

  //   setIsLoading(true);
  //   //   console.log({ ads, path: router.asPath });

  //   dfp.createSlots(ads, enableLazyload);

  //   setIsLoading(false);

  //   const handleRouteChangeStart = (url: string) => {
  //     if (window.location.pathname !== url) {
  //       setIsLoading(true);
  //       dfp.removeSlots();
  //       dfp.createSlots(ads, enableLazyload);
  //     }
  //   };
  //   console.log({ ads });
  //   const handleRouteChangeComplete = () => {
  //     setIsLoading(false);
  //   };

  //   router.events.on("routeChangeStart", handleRouteChangeStart);
  //   router.events.on("routeChangeComplete", handleRouteChangeComplete);

  //   return () => {
  //     router.events.off("routeChangeStart", handleRouteChangeStart);
  //     router.events.off("routeChangeComplete", handleRouteChangeComplete);
  //   };
  // }, [router, enableLazyload, ads]);

  // useEffect(() => {
  //   const searchParams = new URLSearchParams(window.location.search);

  //   if (!!debug && !searchParams.has("google_console")) {
  //     searchParams.append("google_console", "1");
  //     window.location = `${window.location.pathname}?${searchParams}` as any;
  //   }

  //   if (!debug && searchParams.has("google_console")) {
  //     searchParams.delete("google_console");

  //     const search = `${searchParams}`.length > 0 ? `?${searchParams}` : "";
  //     window.location = `${window.location.pathname}${search}` as any;
  //   }
  // }, [debug]);

  // const adsContext = {
  //   isLoading,
  //   addSlot,
  // };

  const { config } = useContext<publicationTypes>(PublicationContext);

  return (
    <>
      <AdsContext.Provider value>{children}</AdsContext.Provider>
      <Script
        src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        async
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `
            var googletag = googletag || {};
            googletag.cmd = googletag.cmd || [];
          `,
        }}
      />
      <script
        async
        src="https://micro.rubiconproject.com/prebid/dynamic/11022.js?group=tindle"
      />
      <script
        async
        src={
          process.env.NEXT_PUBLIC_ENV_STATE === "dev"
            ? "https://tindle.1xl.adopstar.uk/tindle_beta.js"
            : "https://tindle.1xl.adopstar.uk/tindle.js"
        }
        onError={(e) => {
          console.error("Error loading the script", e);
        }}
      />
      {/** Grapeshot */}
      <script
  dangerouslySetInnerHTML={{
    __html: `
      "use strict";
      try {
        var gs_channels="DEFAULT";
        !function(){
          var n=window.location!==window.top.location?document.referrer:window.location,o=document.createElement("script");
          o.async=!0;
          o.onload=function(){
            googletag.cmd.push(function(){
              googletag.pubads().setTargeting("gs_cat",gs_channels)
            })
          };
          o.src="https://onexl.gscontxt.net/multizone/channels.cgi?url="+encodeURIComponent(n);
          document.head.appendChild(o);
        }();
      } catch (e) {
        console.error("Error in script:", e);
      }
    `,
  }}
/>

      {/**End Grapeshot */}
    </>
  );
};
