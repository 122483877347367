import { useEffect } from "react";
import type { AppProps, NextWebVitalsMetric } from "next/app";
import { GlobalStyles, handleTheme } from "common/theme/Styles";
import { ThemeProvider } from "styled-components";
import ErrorBoundary from "Components/ErrorBoundary";
import { DefaultSeo } from "next-seo";
import { AuthContextProvider } from "common/context/Auth";
import { Router, useRouter } from "next/router";
import * as gtag from "../../lib/gtag";
import { ads } from "common/ads";
import { AdsProvider } from "common/ads/AdsProvider";
import { PreviewContextProvider } from "common/context/Preview";
import "../common/theme/react-draft-wysiwyg.css";
import "../common/theme/gallery.css";
import "./styles/globals.css";
import { PublicationContextProvider } from "common/context/Publication";
import NProgress from "nprogress";
import {
  abergavennychronicleConfig,
  altonheraldConfig,
  bordonheraldConfig,
  breconradnorConfig,
  cambrianNewsConfig,
  chepstowbeacon,
  CreditonCourier,
  DartmouthChronicle,
  DawlishGazette,
  farnhamheraldConfig,
  haslemereheraldConfig,
  IvybridgeSouthBrentGazette,
  KingsbridgeSalcombeGazette,
  liphookheraldConfig,
  MidDevonAdvertiser,
  monmouthshirebeaconConfig,
  narberthAndWhitlandTodayConfig,
  OkehamptonTimes,
  pembrokeTodayConfig,
  petersfieldpostConfig,
  rossgazetteConfig,
  SouthHamsGazette,
  TamarValleyTimes,
  tavistockToday,
  TeignmouthPost,
  tenbyTodayConfig,
  theforesterConfig,
  theforestreviewConfig,
  TotnesTimes,
  IOM,
  TheCornishTimes,
  BudeStrattonPost,
  Holsworth,
  CornishDevonPost,
  MidsomerNorton,
  WellingtonWeeklyNews,
  WestSomersetFreePress,
  Voice,
  WokingAndDaily,
} from "common/config";
import { DataLayerProvider } from "common/context/DataLayer";

const config: { [unit: string]: any } = {
  ["petersfieldpost.co.uk"]: petersfieldpostConfig,
  ["farnhamherald.com"]: farnhamheraldConfig,
  ["altonherald.com"]: altonheraldConfig,
  ["bordonherald.com"]: bordonheraldConfig,
  ["haslemereherald.com"]: haslemereheraldConfig,
  ["liphookherald.com"]: liphookheraldConfig,
  ["abergavennychronicle.com"]: abergavennychronicleConfig,
  ["monmouthshirebeacon.co.uk"]: monmouthshirebeaconConfig,
  ["chepstowbeacon.co.uk"]: chepstowbeacon,
  ["brecon-radnor.co.uk"]: breconradnorConfig,
  ["cambrian-news.co.uk"]: cambrianNewsConfig,
  ["tenby-today.co.uk"]: tenbyTodayConfig,
  ["narberth-and-whitland-today.co.uk"]: narberthAndWhitlandTodayConfig,
  ["pembroke-today.co.uk"]: pembrokeTodayConfig,
  ["theforester.co.uk"]: theforesterConfig,
  ["rossgazette.com"]: rossgazetteConfig,
  ["theforestreview.co.uk"]: theforestreviewConfig,
  ["tavistock-today.co.uk"]: tavistockToday,
  ["middevonadvertiser.co.uk"]: MidDevonAdvertiser,
  ["dawlish-today.co.uk"]: DawlishGazette,
  ["teignmouth-today.co.uk"]: TeignmouthPost,
  ["dartmouth-today.co.uk"]: DartmouthChronicle,
  ["ivybridge-today.co.uk"]: IvybridgeSouthBrentGazette,
  ["kingsbridge-today.co.uk"]: KingsbridgeSalcombeGazette,
  ["southhams-today.co.uk"]: SouthHamsGazette,
  ["totnes-today.co.uk"]: TotnesTimes,
  ["tamarvalleytimes.co.uk"]: TamarValleyTimes,
  ["okehampton-today.co.uk"]: OkehamptonTimes,
  ["creditoncourier.co.uk"]: CreditonCourier,
  ["iomtoday.co.im"]: IOM,
  ["wokingnewsandmail.co.uk"]: WokingAndDaily,
  ["thepost.uk.com"]: TheCornishTimes,
  ["bude-today.co.uk"]: BudeStrattonPost,
  ["holsworthy-today.co.uk"]: Holsworth,
  ["cornish-times.co.uk"]: TheCornishTimes,
  ["mnrjournal.co.uk"]: MidsomerNorton,

  ["wellington-today.co.uk"]: WellingtonWeeklyNews,
  ["wsfp.co.uk"]: WestSomersetFreePress,
  ["voicenewspapers.co.uk"]: Voice,
};

Router.events.on("routeChangeStart", (url) => {
  NProgress.start();
});
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

export function reportWebVitals(metric: NextWebVitalsMetric) {
}

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  const publication = pageProps.publication
    ? pageProps.publication
    : "abergavennychronicle.com";
  useEffect(() => {
    console.log(window !== undefined && window.location.hostname);
    console.log({ state: process.env.NEXT_PUBLIC_ENV_STATE });
    const handleRouteChange = (url: URL) => {
      // gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={handleTheme(publication)}>
        <ErrorBoundary>
          <DefaultSeo
            openGraph={{
              type: "website",
              locale: "en",
              url: config[publication].url,
              site_name: config[publication].pub,
            }}
          />
          <PublicationContextProvider pub={publication}>
            <AuthContextProvider>
              <PreviewContextProvider>
                <AdsProvider>
                  <DataLayerProvider>
                    <Component {...pageProps} />
                  </DataLayerProvider>
                </AdsProvider>
              </PreviewContextProvider>
            </AuthContextProvider>
          </PublicationContextProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </>
  );
}
export default MyApp;
